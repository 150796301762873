import React from "react";
import NavigationBar from "./components/NavigationBar";
import Footer from "./components/Footer";

const Poetry = () => {
    return(
        <div id="everything">
            <NavigationBar />
            <div className="text-black">
            <div className='text-center text-black justify-center text-xl md:text-5xl lg: text-6xl xl:text-7xl pt-4 pb-4'>
                Parraga Lab Poetry Corner
            </div>
            <div className='text-center justify-center underline text-lg md:text-3xl lg: text-4xl xl:text-4xl pt-4 pb-4'>
                Dr. McCormack's Retirement Poetry
            </div>
            <img src="./images/Resp Division Photo cropped.png" width={`100%`} alt="Respirology Division after Dr. David McCormack's Last Grand Rounds Presentation" />
            <br></br>
            <div className='text-center justify-center font-bold text-lg md:text-xl lg:text-2xl xl:text-4xl pt-4 pb-4'>
                Poem by Grace Parraga
            </div>
            <div className='text-center justify-center text-lg md:text-xl lg:text-2xl xl:text-4xl'>
                To be sung to the tune of Mary’s Boy Child   <br></br><br></br>
                Long time ago in Ottawa, a new kid born today <br></br>
                And lungs will be better forever more, because of this very day<br></br>
                Hark now hear, the angels sing, a new kid born that day<br></br>
                And lungs will be better forever more, because of this very day.<br></br><br></br> 

                In the town of London, he worked at night, to treat people with lung disease<br></br>
                With guidelines and his medical acumen, he improved morbidity and mortality!<br></br>
                Hark now hear, the angels sing, the respirologist in clinic that day<br></br>
                And lungs will be better forever more, because of his work every day. <br></br><br></br>

                In his spare time, he supported the APILAb with his good hearted respirology<br></br>
                And the lab published many manuscripts on pulmonary imaging<br></br>
                Hark now hear, the angels sing, a researcher in the lab that day<br></br>
                And lungs will be better forever more, because of his collegiality. <br></br><br></br>

                We say goodbye to our favourite Med, as he approaches his next exciting phase<br></br>
                We hope he remembers us favourably, because we’ll miss him every day!<br></br>
                Hark now hear, the angels sing, as he golfs and strums through his next many days<br></br>
                And lungs will be better forever more, because of his many working days.<br></br>
                Hark now hear, the angels sing, as he golfs and strums through his next many days<br></br>
                And lungs will be better forever more, because of his working days.<br></br><br></br>
            </div>
            <div className='text-center justify-center font-bold text-lg md:text-xl lg:text-2xl xl:text-4xl pt-4 pb-4'>
                Poem by Angela Wilson
            </div>
            <div className='text-center justify-center text-lg md:text-xl lg:text-2xl xl:text-4xl'>
                ONE IN A MILLION<br></br><br></br>

                With laughter that echoes through the halls, <br></br>
                A heart so warm, answering patients’ call.<br></br>
                Through research and science, you’ve paved the way,<br></br> 
                Helping others to live better, day by day.<br></br><br></br>

                With golf clubs in hand, you start a new pace,<br></br>
                And a guitar to strum in your peaceful space.<br></br>
                As a grandfather, you’ll cherish each day,<br></br>
                Guiding young hearts in your special way.<br></br><br></br>

                On the farm, where the fields stretch wide,<br></br>
                You’ll find your peace, with nature as guide.<br></br>
                Camping trips beneath the stars, feeling free,<br></br>
                Embracing the joys of life’s harmony.<br></br><br></br>

                Now you retire, but your work lives on, <br></br>
                Your impact remains, steady and strong.<br></br>
                Thank you Dave, for all the care you’ve given,<br></br>
                You’ve left big shoes to fill, you’re one in a million!<br></br><br></br>
            </div>
            <div className='text-center justify-center font-bold text-lg md:text-xl lg:text-2xl xl:text-4xl pt-4 pb-4'>
                Poem by Lauren Zelko
            </div>
            <div className='text-center justify-center text-lg md:text-xl lg:text-2xl xl:text-4xl'>
                With every study, you’ve helped us through,<br></br>
                Your knowledge and referrals, we all thank you.<br></br>
                A great support to lung research, you’ve been,<br></br>
                Your work and legacy will always shine within.  <br></br><br></br>

            </div>
            <div className='text-center justify-center font-bold text-lg md:text-xl lg:text-2xl xl:text-4xl pt-4 pb-4'>
                Poem by Sam Tcherner
            </div>
            <div className='text-center justify-center text-lg md:text-xl lg:text-2xl xl:text-4xl'>
                To a healer of lungs, whose work's been so grand,<br></br>
                Your time to relax, adventure’s at hand.<br></br>
                Happy retirement, may peace fill each day,<br></br>
                And new joys of life take your breath away!<br></br><br></br>
            </div>
            <div className='text-center justify-center font-bold text-lg md:text-xl lg:text-2xl xl:text-4xl pt-4 pb-4'>
                Poem by Ali Mozaffaripour
            </div>
            <div className='text-center justify-center text-lg md:text-xl lg:text-2xl xl:text-4xl'>
                From caring for lungs to guiding with grace, <br></br>
                Your wisdom and kindness will leave a strong trace. <br></br>
                Now it’s time to rest, as new journeys begin, <br></br>
                Wishing you joy where your next steps spin!<br></br><br></br>
            </div>
            <div className='text-center justify-center font-bold text-lg md:text-xl lg:text-2xl xl:text-4xl pt-4 pb-4'>
                Poem by Eveline Durom
            </div>
            <div className='text-center justify-center text-lg md:text-xl lg:text-2xl xl:text-4xl'>
                A lung's labyrinth ways, unseen, unknown,<br></br>
                By Dr McCormack's gaze are brought to light. <br></br>
                His lens unveils the secrets coughs may hold, <br></br>
                The mysteries of wheezing, rasp, and sigh.<br></br><br></br>

                With skill he wields this tool of science' art, <br></br>
                To diagnose and heal, to mend, to save.<br></br>
                His passion burns, a fire in the heart,<br></br>
                For those who struggle just to breathe and rave.<br></br>
                So let us raise a glass to David's might, <br></br>
                A champion of lungs, who brought them back to light.<br></br><br></br>
            </div>
            <div className='text-center justify-center underline text-lg md:text-3xl lg: text-4xl xl:text-4xl pt-4 pb-4'>
                December 2022
            </div>
            <div className='text-center justify-center font-bold text-lg md:text-xl lg:text-2xl xl:text-4xl pt-4 pb-4'>
                Poem by Grace Parraga
            </div>
            <div className='text-center justify-center text-lg md:text-xl lg:text-2xl xl:text-4xl'>
                Baby it's Futboly outside<br></br><br></br>
                I really can't breathe -<br></br>
                Baby I can't abide<br></br><br></br>

                I can't even sneeze -<br></br>
                try some budesonide<br></br><br></br>

                I wish I could inhale - something for my wheeze -<br></br>
                I've got some trelegy, give it a squeeze<br></br><br></br>

                My chest doctor will start to worry-<br></br>
                Think hard about triple therapy<br></br><br></br>

                My MRI will look really pore<br></br>
                Rest awhile and watch Mrocco Score<br></br><br></br>

                I can't say hooray<br></br>
                ICS-LABA-LAMA will help your breathing today!<br></br><br></br>
            </div>
            <br></br>
            <div className='text-center justify-center font-bold text-lg md:text-xl lg:text-2xl xl:text-4xl pt-4 pb-4'>
                Poem by Alex Matheson
            </div>
            <div className='text-center justify-center text-lg md:text-xl lg:text-2xl xl:text-4xl'>
                Oh, Christmas time is here,<br></br>
                The season of good cheer,<br></br>
                But for me it's not so jolly,<br></br>
                'Cause I can't breathe, my asthma's acting folly.<br></br><br></br>

                I wheeze and gasp for air,<br></br>
                As I try to decorate my tree,<br></br>
                My inhaler's by my side,<br></br>
                But it's not enough, I need to flee.<br></br><br></br>

                I head outside to find some space,<br></br>
                To catch my breath and clear my face,<br></br>
                And as I walk, I see a ball,<br></br>
                A soccer ball, just waiting for a fall.<br></br><br></br>

                I kick it with my feet,<br></br>
                And chase it down the street,<br></br>
                And as I run, I feel my chest,<br></br>
                Relax and breathe, I've passed the test.<br></br><br></br>

                So Christmas may not be perfect,<br></br>
                But with soccer and my inhaler,<br></br>
                I can still have fun,<br></br>
                And enjoy the season with my friends and family,<br></br>
                Merry Christmas to all, and to all a good night!<br></br><br></br>

                Silent night, holy night,<br></br>
                All is calm, all is bright.<br></br>
                But not for me, I can't breathe,<br></br>
                My asthma's acting up, I need some relief.<br></br><br></br>

                Inhaler by my side,<br></br>
                I try to calm my wheezing chest,<br></br>
                But the air is cold and dry,<br></br>
                It's making my symptoms worse, I must confess.<br></br><br></br>

                Asthmatic night, asthmatic night,<br></br>
                Breathe in, breathe out, try with all my might,<br></br>
                To calm my lungs and ease my plight,<br></br>
                Asthmatic night, asthmatic night.<br></br><br></br>

                The air is still, not a sound,<br></br>
                But inside my chest, a battle's raging,<br></br>
                I'm struggling to catch my breath,<br></br>
                As the asthma attack keeps on engaging.<br></br><br></br>

                But then I hear a gentle voice,<br></br>
                Soothing and calm, it makes me rejoice,<br></br>
                It's the voice of my loved ones,<br></br>
                Saying "It's okay, we're here for you, don't be scared, we'll get through this together, just hold on tight."<br></br><br></br>

                Asthmatic night, asthmatic night,<br></br>
                Breathe in, breathe out, with all my might,<br></br>
                I know I'm not alone, I have my loved ones by my side,<br></br>
                Asthmatic night, asthmatic night.<br></br>
                You know SABAs and SAMAs and LABAs and LAMAs<br></br>
                Symba and mepo and dupi and Xolair<br></br>
                But do you recall<br></br>
                The most effective treatment of alllll<br></br><br></br>

                Rudolf the asthmatic reindeer,<br></br>
                Had a very hacking cough,<br></br>
                And if you ever heard it,<br></br>
                All of the other treatments,<br></br>
                Used to give him quakes and shakes,<br></br>
                He could never go and travel,<br></br>
                For his health insurance sake.<br></br><br></br>

                Then one foggy Christmas eve,<br></br>
                Alonso came to say,<br></br>
                Rudolph with your kicks so high,<br></br>
                Won't you play for Uruguay?<br></br><br></br>

                So he just took some benra,<br></br>
                And normalized his FEV,<br></br>
                Rudolph the healthy Reindeer,<br></br>
                Played in FIFA thanks to briii-berrrr-yyyyyyy<br></br><br></br>
            </div>
            <br></br>
            <div className='text-center justify-center font-bold text-lg md:text-xl lg:text-2xl xl:text-4xl pt-4 pb-4'>
                Poem by Alexander Biancaniello
            </div>
            <div className='text-center justify-center text-lg md:text-xl lg:text-2xl xl:text-4xl'>
                Frost on window panes<br></br>
                Cold breath in seizing airways<br></br>
                Hurrying indoors<br></br><br></br>

                Inside, where it's warm<br></br>
                Friends cheering at the TV<br></br>
                Kick, pass, shoot - and goal!<br></br><br></br>
            </div>
            <div className='text-center justify-center font-bold text-lg md:text-xl lg:text-2xl xl:text-4xl pt-4 pb-4'>
                Poem by Angela Wilson
            </div>
            <div className='text-center justify-center text-lg md:text-xl lg:text-2xl xl:text-4xl'>
                Twas the night before the final match when all through the lab,<br></br>
                Not a creature was wheezing, thanks to benralizumab.<br></br>
                The N-95s were hung by the pleth box with care,<br></br>
                In hopes that the 30th ETHA patient soon would be there.<br></br><br></br>
                
                The students were nestled all snug with their abstracts,<br></br>
                While visions of awards and papers danced in their heads.<br></br>
                And Grace in her lab swag and I in my scrubs<br></br>
                Had just settled down to watch FIFA world cup.<br></br>
                
                When out in the hall there arose such a clatter,<br></br>
                We sprang from our chairs to see what was the matter.<br></br>
                Away to the atrium I flew in a hurry,<br></br>
                Tore open the door when Cory and Anurag said its OK don't worry.<br></br><br></br>
                
                Now Grace!  Now Angela!  Now Marrissa!  Now Alex! <br></br>
                Now Kiran!  Now Ivailo!  Now Paulina!  Now Maks!<br></br>
                To the PFT lab, to the deep learning cave hall!<br></br>
                Now dash away, dash away, dash away all!<br></br><br></br>
                
                The doctors were dressed in soccer jerseys from their favourite team,<br></br>
                And their briefcases were bursting with referrals for Trelegy.<br></br>
                A bundle of referrals from asthma clinic<br></br>
                Made them both look so much like St. Nick!<br></br><br></br>
                
                Grace sprang to the table, to her team gave a whistle,<br></br>
                And away we all flew like the down of a thistle.<br></br>
                But I heard Cory exclaim as he walked out of sight,<br></br>
                HAPPY CHRISTMAS TO ALL AND TO ALL A GOOD NIGHT!<br></br><br></br>
            </div>
            <div className='text-center justify-center font-bold text-lg md:text-xl lg:text-2xl xl:text-4xl pt-4 pb-4'>
                Poem by Hana Serajeddini
            </div>
            <div className='text-center justify-center text-lg md:text-xl lg:text-2xl xl:text-4xl'>
                Neymar the dramatic diver<br></br>
                Had a very shiny smile<br></br>
                And if an asthmatic saw it<br></br>
                They may wheeze for a while<br></br><br></br>

                Most of the world cup watchers<br></br>
                Love to watch Neymar play<br></br>
                But asthmatics have to use their puffers<br></br>
                And maybe a nasal spray!<br></br><br></br>
            </div>
            <div className='text-center justify-center font-bold text-lg md:text-xl lg:text-2xl xl:text-4xl pt-4 pb-4'>
                Poem by Ivailo Petrov
            </div>
            <div className='text-center justify-center text-lg md:text-xl lg:text-2xl xl:text-4xl'>
                Snow on the Christmas field,<br></br>
                With no win ever sealed<br></br>
                Davies scores ho ho ho<br></br>
                players go go go<br></br><br></br>

                players run run run<br></br>
                plenty of time under the winter sun<br></br>
                but loosing every game<br></br>
                is very lame<br></br><br></br>

                watching games is a struggle<br></br>
                and can be a trouble<br></br>
                but doing the asthma Measure<br></br>
                is a real pleasure<br></br><br></br>

                living the life in the lab<br></br>
                is a real life recap<br></br>
                prepares you not to be a schmuck<br></br>
                everybody - good luck!<br></br><br></br>
            </div>
            <div className='text-center justify-center font-bold text-lg md:text-xl lg:text-2xl xl:text-4xl pt-4 pb-4'>
                Poem by Kiran Kooner
            </div>
            <div className='text-center justify-center text-lg md:text-xl lg:text-2xl xl:text-4xl'>
                You better breathe deep<br></br>
                You better keep still<br></br>
                You better not sleep<br></br>
                Or he won't be chill<br></br>
                Santa Claus is coming to lab<br></br><br></br>

                He's scannin' ETHA<br></br>
                He's polarizing<br></br>
                He's gonna find out who's thesis-writing<br></br>
                Santa Claus is coming to lab<br></br><br></br>

                He sees you watching futbol<br></br>
                And he knows when Messi scores<br></br>
                He knows who won and lost the game<br></br>
                So keep watching soccer wars<br></br><br></br>

                You better breathe deep<br></br>
                You better keep still<br></br>
                You better not sleep<br></br>
                Or he won't be chill<br></br>
                Santa Claus is coming to lab<br></br><br></br><br></br>
            </div>
            <div className='text-center justify-center font-bold text-lg md:text-xl lg:text-2xl xl:text-4xl pt-4 pb-4'>
                Poem by Maksym Sharma
            </div>
            <div className='text-center justify-center text-lg md:text-xl lg:text-2xl xl:text-4xl'>
                Problems in your airway tree?<br></br>
                Don't worry, we got you triple-therapy!<br></br><br></br>

                We are all filled with Christmas cheer<br></br>
                And excited to watch the world-cup this year<br></br><br></br>

                Even with a corrupt FIFA referee, we'll all be glee!<br></br><br></br>
            </div>
            <div className='text-center justify-center font-bold text-lg md:text-xl lg:text-2xl xl:text-4xl pt-4 pb-4'>
                Poem by Marrissa McIntosh
            </div>
            <div className='text-center justify-center text-lg md:text-xl lg:text-2xl xl:text-4xl'>
                'Twas the day of FIFA finals, when all through the lab<br></br>
                Not a creature was working, and not a single new tab (from Maks);<br></br>
                And I with my spreadsheets in the deep learning cave,<br></br>
                Had just settled in for an asthma VDP rave,<br></br><br></br>

                When out in the hall there arose such a clatter,<br></br>
                I sprang from my chair to see what was the matter.<br></br>
                When, what to my wondering eyes should appear,<br></br>
                But the entire APILab with cups full of ... coffee,<br></br><br></br>

                The players move the ball, so lively and quick,<br></br>
                I knew in a moment which team was first pick.<br></br>
                The favorite team scores, and the growing crowd cheers<br></br>
                But the rivals don't quit and their goal brings new fears;<br></br><br></br>

                With one minute left, and both teams tied,<br></br>
                Everyone yells out "that was offside!"<br></br>
                Our player falls and the ref blows the whistle,<br></br>
                One penalty kick, shot like a missile,<br></br><br></br>

                The crowd erupts as the ball goes in,<br></br>
                And with that, an exciting World Cup win.<br></br>
                With the end of FIFA, to my great delight,<br></br>
                HAPPY CHRISTMAS TO ALL, AND TO ALL A GOOD-NIGHT!<br></br><br></br>
            </div>
            <div className='text-center justify-center font-bold text-lg md:text-xl lg:text-2xl xl:text-4xl pt-4 pb-4'>
                Poem by Paulina Wyszkiewicz
            </div>
            <div className='text-center justify-center text-lg md:text-xl lg:text-2xl xl:text-4xl'>
                Deck the lab with triple therapy<br></br>
                Fa la la la la, la la la la<br></br>
                Hoping patients become merry<br></br>
                Fa la la la la, la la la la<br></br>
                Don we now our mask and lab coat<br></br>
                Fa la la la la, la la la la<br></br>
                APILab research is the GOAT<br></br>
                Fa la la la la, la la la la<br></br><br></br>

                Playing football isn't easy<br></br>
                Fa la la la la, la la la la<br></br>
                Some players may get wheezy<br></br>
                Fa la la la la, la la la la<br></br>
                In the deep learning cave we watch<br></br>
                Fa la la la la, la la la la<br></br>
                World cup season is topnotch<br></br>
                Fa la la la la, la la la la<br></br><br></br><br></br>
            </div>
            <div className='text-center justify-center font-bold text-lg md:text-xl lg:text-2xl xl:text-4xl pt-4 pb-4'>
                Poem by Vedanth Desaigoudar
            </div>
            <div className='text-center justify-center text-lg md:text-xl lg:text-2xl xl:text-4xl'>
                This Cold Winter Christmas,<br></br>
                Let's wish all our asthma participants an improved overall fitness!<br></br>
                To our football players abroad, let's see which team is the most ambitious!<br></br>
                Stay safe, stay warm, and most importantly make something delicious!<br></br><br></br><br></br>
            </div>
            <div className='text-center justify-center underline text-lg md:text-3xl lg: text-4xl xl:text-4xl pt-4 pb-4'>
                December 2021
            </div>
            <div className='text-center justify-center font-bold text-lg md:text-xl lg:text-2xl xl:text-4xl pt-4 pb-4'>
                Poem by Grace Parraga
            </div>
            <div className='text-center justify-center text-lg md:text-xl lg:text-2xl xl:text-4xl'>
                I'm dreaming of a COVIDFREE Christmas<br></br>
                With every dissolved phase result, I cry<br></br><br></br>

                Where the airways, alveoli and barrier glisten<br></br>
                and patients listen<br></br>
                To hear pfts blow at 100%<br></br><br></br>

                I'm dreaming of a COVIDFREE Christmas<br></br>
                With every dissolved phase result, I cry<br></br><br></br>

                May your new year bring less COVID<br></br>
                And why?<br></br>
                So all your papers are immediately accepted and shine bright.<br></br><br></br><br></br>
            </div>
            <div className='text-center justify-center font-bold text-lg md:text-xl lg:text-2xl xl:text-4xl pt-4 pb-4'>
                Poem by Kiran Kooner
            </div>
            <div className='text-center justify-center text-lg md:text-xl lg:text-2xl xl:text-4xl'>
                This Christmas Eve,<br></br>
                Let's LIVE COVID FREE<br></br>
                Protect your airway tree<br></br>
                Mask up and it's a guarantee<br></br>
                Please, don't disagree!<br></br><br></br><br></br>
            </div>
            <div className='text-center justify-center font-bold text-lg md:text-xl lg:text-2xl xl:text-4xl pt-4 pb-4'>
                Poem by Marrissa McIntosh
            </div>
            <div className='text-center justify-center text-lg md:text-xl lg:text-2xl xl:text-4xl'>
                Although this year was full of rejections<br></br>
                Like ISMRM, case reports, and AERFLO<br></br>
                The holidays are the perfect time for reflections<br></br>
                And testing out some new directions<br></br>
                Like watching Ross get excited for snow<br></br>
                Giving friends and family love and affections<br></br>
                And making sure everyone has COVID injections<br></br>
                Let's end the year in a cheerful glow<br></br>
                And without any COVID infections<br></br>
                And cheers to a year of imperfections<br></br><br></br><br></br>
            </div>
            <div className='text-center justify-center font-bold text-lg md:text-xl lg:text-2xl xl:text-4xl pt-4 pb-4'>
                Poem by Paulina Wyszkiewicz
            </div>
            <div className='text-center justify-center text-lg md:text-xl lg:text-2xl xl:text-4xl'>
                Computed tomography is a popular imaging modality.<br></br>
                Hyperpolarized 129Xe lights up the lungs like a Christmas tree.<br></br>
                Respirologists treat many types of lung disease, such as asthma, pneumonia and COPD.<br></br>
                Imaging scientists become jolly when the SNR value is quite lofty.<br></br>
                Simulating ultra-low-dose CT is performed for dose reduction feasibility. <br></br>
                Tobacco cigarettes are very unhealthy, they produce smoke you inhale like a chimney. <br></br>
                MRI segmentation of the lung is key, it computes an important value called VDP.<br></br>
                Alveoli transfer oxygen to the bloodstream, they are the endpoint of the airway tree.<br></br>
                Scientific research is very necessary, successful publications lead to festivity.<br></br><br></br><br></br>
            </div>
            <div className='text-center justify-center font-bold text-lg md:text-xl lg:text-2xl xl:text-4xl pt-4 pb-4'>
                Poem by Alexander Matheson
            </div>
            <div className='text-center justify-center text-lg md:text-xl lg:text-2xl xl:text-4xl'>
                I don't want a lot for Christmas,<br></br>
                Thises is just one thing I need,<br></br>
                I don't care the impact factor is well below 73,<br></br>
                I'll just check the mean and mode,<br></br>
                Before I go click upload,<br></br>
                My hypothesis is true,<br></br>
                All I want for Christmas is Blue!<br></br><br></br>

                I don't want a lot for Christmas,<br></br>
                There is just one thing I need,<br></br>
                I don't care about updating,<br></br>
                My languishing ORCID-ID,<br></br>
                I've got to perform corrections<br></br>
                At an alpha of point five,<br></br>
                Then go upload my first draft,<br></br>
                Before I crash my hard-drive.<br></br><br></br>

                I want citations for my own,<br></br>
                Now the literature will show,<br></br>
                My hypothesis is true,<br></br>
                All I want for Christmas is Blue!<br></br><br></br>

                Oh I won't ask for much this Christmas,<br></br>
                Just keep my p-values all low,<br></br>
                I'm just gonna keep on waiting,<br></br>
                For Grace to tell me to upload,<br></br>
                I won't make a draft and send it,<br></br>
                To reviewers for feedback,<br></br>
                I won't even stay awake to<br></br>
                Find that they have been side tracked.<br></br><br></br>

                Why isn't it in press tonight,<br></br>
                All my analysis is right?<br></br>
                What more can I do?<br></br>
                Oh bother, all I want for Christmas is reviews!<br></br><br></br>

                Oh I don't want a lot for Christmas,<br></br>
                To the editor I pled,<br></br>
                "I just want to see my paper,<br></br>
                Posted on Pubmed,"<br></br>
                Oh I just want this to be done,<br></br>
                Revisions they are never fun,<br></br>
                The galley proofs are through!<br></br>
                All I want for Christmas is Blue!<br></br><br></br><br></br>
            </div>
            <div className='text-center justify-center font-bold text-lg md:text-xl lg:text-2xl xl:text-4xl pt-4 pb-4'>
                Poem by Maksym Sharma
            </div>
            <div className='text-center justify-center text-lg md:text-xl lg:text-2xl xl:text-4xl'>
                Even if COVID has the North pole in lockdown,<br></br>             
                Christmas poems and happiness is still in town,<br></br><br></br>
                APILab brought it with them today,<br></br>
                The Weasel is celebrating this day,<br></br><br></br>
                Motivating to publish work that takes the virus down!<br></br><br></br><br></br>
            </div>
            <div className='text-center justify-center font-bold text-lg md:text-xl lg:text-2xl xl:text-4xl pt-4 pb-4'>
                Poem by Ivailo Petrov
            </div>
            <div className='text-center justify-center text-lg md:text-xl lg:text-2xl xl:text-4xl'>
                I like to see the Christmas lights<br></br>
                I like to see the Christmas spirit<br></br>
                I like to see the Christmas nights<br></br>
                I like to see the Christmas limit<br></br><br></br>

                I like to hear the Christmas song<br></br>
                I like to hear the Christmas music<br></br>
                I like to hear the Christmas gong<br></br>
                I like to hear the Christmas classic<br></br><br></br>

                I like to get the Christmas gift<br></br>
                I like to get the Christmas break<br></br>
                I like to get the Christmas lift<br></br>
                I like to get the Christmas cake<br></br><br></br><br></br>
            </div>
            <div className='text-center justify-center underline text-lg md:text-3xl lg: text-4xl xl:text-4xl pt-4 pb-4'>
                December 2019
            </div>
            <div className='text-center justify-center font-bold text-lg md:text-xl lg:text-2xl xl:text-4xl pt-4 pb-4'>
                My Favourite Things: By Grace Parraga <br></br> 
                based entirely on the lyrics and music of Rogers and Hammerstein
            </div>
            <div className='text-center justify-center text-lg md:text-xl lg:text-2xl xl:text-4xl'>
                Sputum drops on lab coats<br></br><br></br>
                And mucous plugs in asthma<br></br><br></br>
                Bronchiolitis obliterans in vapers from Canada<br></br><br></br>

                Brown paper packages lost in Milwaukee<br></br>
                These are a few of my anxieties<br></br><br></br>

                Cyan-colored ventilation and piiink perfusion<br></br>
                Manuscripts and posters<br></br>
                And galleys with booboos<br></br><br></br>

                Wild data findings that fly in the face of reality<br></br>
                These are a few of my perplexities......<br></br><br></br>

                Girls with bad asthma and men with blue pallour<br></br>
                Snowstorms that cancel our visits from Stratford.<br></br>
                Early winter weather that costs us MRI bookings<br></br>
                These are a few of my serious wooories<br></br><br></br>

                When the FOT breaks<br></br>
                When the FENO dies<br></br>
                When the coils are bust<br></br><br></br>

                I simply remember my APILab top team<br></br><br></br>

                And then I don't feel so sad<br></br><br></br><br></br>
            </div>
            <div className='text-center justify-center font-bold text-lg md:text-xl lg:text-2xl xl:text-4xl pt-4 pb-4'>
                Poem by Danielle Knipping
            </div>
            <div className='text-center justify-center text-lg md:text-xl lg:text-2xl xl:text-4xl'>
                As we come to the end of 2019<br></br>
                The end of AERFLO can almost be seen<br></br>
                80 AERFLO scans in the course of a year<br></br>
                So it's time to relax and enjoy a cold beer<br></br><br></br>

                We sent 40 newsletters, gave 72 doses<br></br>
                To promote natural killer cells to induce apoptosis<br></br>
                We learned one new language, hablo espa単ol,<br></br> 
                And helped get severe asthma under control<br></br><br></br>

                Now 2020 has almost arrived<br></br>
                Quite frankly I'm just glad we survived<br></br>
                Now it's time to spread holiday cheer<br></br>
                So happy holidays to all and a happy new year!<br></br><br></br><br></br>
            </div>
            <div className='text-center justify-center font-bold text-lg md:text-xl lg:text-2xl xl:text-4xl pt-4 pb-4'>
                Poem by Tamas Lindenmaier
            </div>
            <div className='text-center justify-center text-lg md:text-xl lg:text-2xl xl:text-4xl'>
                Hi everybody, how are you?<br></br>
                I hope nobody has the flu,<br></br>
                It causes people so much woe.<br></br>
                Kind of like when students go,<br></br>
                Rachel, Andrea and Johnathan will soon,<br></br>
                And the lab will be different as a new decade blooms.<br></br>
                But have no fear, I'm sure things will be fab.<br></br>
                Cause we're doing some great work with asthma and benrab<br></br><br></br><br></br>
            </div>
            <div className='text-center justify-center font-bold text-lg md:text-xl lg:text-2xl xl:text-4xl pt-4 pb-4'>
                Christmas by Marrissa McIntosh
            </div>
            <div className='text-center justify-center text-lg md:text-xl lg:text-2xl xl:text-4xl'>
                Christmas arrives just as one year ends<br></br>
                It's the best time to spend with family and friends<br></br>
                You can go skating at the rink<br></br>
                Or have a warm drink<br></br>
                Or help your mom make the cookies she sends<br></br><br></br>

                This year I'll answer the question again and again<br></br>
                "So tell me what exactly are you researching?"<br></br>
                I'll try my best to explain<br></br>
                And all I'll see on their face is pain<br></br>
                Because I could talk about Xenon MR to no end<br></br><br></br>

                This holiday isn't about gifts or snow aplenty<br></br>
                But giving love and happiness to many<br></br>
                So spread some holiday cheer<br></br>
                And have a holiday beer<br></br>
                And I'll see you all in 2020<br></br><br></br><br></br>
            </div>
            <div className='text-center justify-center font-bold text-lg md:text-xl lg:text-2xl xl:text-4xl pt-4 pb-4'>
                Poem by Jenna Veugen
            </div>
            <div className='text-center justify-center text-lg md:text-xl lg:text-2xl xl:text-4xl'>
                'Twas the day after finals,<br></br>
                and all through the school,<br></br>
                not a student was studying,<br></br>
                not even the fool!<br></br><br></br>

                I packed up for Christmas,<br></br>
                Ready for home,<br></br>
                But before I could leave,<br></br>
                There was somewhere I must go.<br></br><br></br>

                So I wake up early,<br></br>
                And grab a coffee,<br></br>
                Head to Robarts,<br></br>
                And pass by the Christmas tree.<br></br><br></br>

                I sit down at my desk,<br></br>
                And start working away,<br></br>
                When over the wall,<br></br>
                I hear Marissa say:<br></br><br></br>

                "Are you coming for Christmas lunch?<br></br>
                You'll need to write a poem!"<br></br>
                So here I am,<br></br>
                Yea, maybe I'll just go home.<br></br><br></br><br></br>
            </div>
            <div className='text-center justify-center font-bold text-lg md:text-xl lg:text-2xl xl:text-4xl pt-4 pb-4'>
                Poem by Maksym Sharma
            </div>
            <div className='text-center justify-center text-lg md:text-xl lg:text-2xl xl:text-4xl'>
                At Christmas, lights burn brighter,<br></br>
                and friendly hugs get even tighter.<br></br><br></br>

                Across white December nights,<br></br>
                trees are shimmed with tiny lights.<br></br><br></br>

                Delicate snowflakes dancing down,<br></br>
                piling high across the town.<br></br><br></br>

                Holding my excitement one more night,<br></br>
                my parents will soon board their flight.<br></br><br></br>

                After crossing the Atlantic Ocean,<br></br>
                there will surely be a commotion.<br></br><br></br>

                As our reunion will bring great cheer,<br></br>
                my family is finally here.<br></br><br></br>

                This arrival is a Christmas gift,<br></br>
                I see in blue from their Doppler shift.<br></br><br></br>

                For all the joys it will send,<br></br>
                I never want this Christmas to end.<br></br><br></br><br></br>
            </div>
            <div className='text-center justify-center font-bold text-lg md:text-xl lg:text-2xl xl:text-4xl pt-4 pb-4'>
                Poem by Rachel Eddy
            </div>
            <div className='text-center justify-center text-lg md:text-xl lg:text-2xl xl:text-4xl'>
                Christmas break is here<br></br>
		        Family food and thesis<br></br>
		        2 0 1 9 ends<br></br><br></br><br></br>
            </div>
            <div className='text-center justify-center font-bold text-lg md:text-xl lg:text-2xl xl:text-4xl pt-4 pb-4'>
                Christmas Pizza by Andrea Barker
            </div>
            <div className='text-center justify-center text-lg md:text-xl lg:text-2xl xl:text-4xl'>
                Although we might have little snow<br></br>
                I wish I wish for you to know<br></br>
                I just can't wait<br></br>
                For Christmas Day<br></br>
                A tradition filled with dough<br></br><br></br>

                On Christmas day my aunt was born<br></br>
                So after presents in the morn<br></br>
                We hop in the car<br></br>
                We drive somewhat far<br></br>
                Past field that once had corn<br></br><br></br>

                My family's all together<br></br>
                In sweatpants, tees and sweaters<br></br>
                We eat homemade pizza<br></br>
                You're jealous aren't ya<br></br>
                It beats turkey no matter the weather<br></br><br></br><br></br>
            </div>
            <div className='text-center justify-center font-bold text-lg md:text-xl lg:text-2xl xl:text-4xl pt-4 pb-4'>
                Poem by Jonathan MacNeil
            </div>
            <div className='text-center justify-center text-lg md:text-xl lg:text-2xl xl:text-4xl'>
                Cheer<br></br>
                Home<br></br>
                Relaxation<br></br>
                Icicles<br></br>
                Singing<br></br>
                Turkey<br></br>
                Memories<br></br>
                A loving family<br></br>
                Sharing<br></br><br></br><br></br>
            </div>
            <div className='text-center justify-center font-bold text-lg md:text-xl lg:text-2xl xl:text-4xl pt-4 pb-4'>
                Poem by Alexander Matheson
            </div>
            <div className='text-center justify-center text-lg md:text-xl lg:text-2xl xl:text-4xl'>
                Every who in the lung lab was graduating soon,<br></br>
                But Alex was not, just what was he to do?<br></br><br></br>

                Alex needed papers - a whole paper pile,<br></br>
                But what could he do? This would take a while,<br></br>
                It could be his data didn't correlate right,<br></br>
                Or his line of best fit was not at all tight,<br></br>
                But I think the most likely reason of all,<br></br>
                may have been that his n was two sizes too small.<br></br><br></br>

                They're all leaving Robarts, they're all doing so well,<br></br>
                I think I just have to stop them! He yelled,<br></br>
                I don't want to have a new lab entourage,<br></br>
                I know, I'll resort to some fine sabotage...<br></br><br></br>

                So he search for the lab keys, then made a bad promise,<br></br>
                That when he was done, he'd return them to Tamas.<br></br>
                Alex was puzzled for a moment, by keys 1, 3, and 2,<br></br>
                Before he had realized only 4 would do.<br></br>
                Inside the filing cabinet, all the charts in a row,<br></br>
                LCI data, he barked, is the first thing to go!<br></br><br></br>

                Then to the PFT lab he slithered and slunk,<br></br>
                To steal the pleth-box - he was so power-drunk!<br></br>
                Nose clips, and helium, and an old asthma puffer!<br></br>
                Without all these tools they surely will suffer!<br></br>
                And greedy old Alex, filled, filled with a dark yearning,<br></br>
                Downloaded all the data, for HIS machine learning.<br></br><br></br>

                Then he slink to the scanner and took Robarts' best!<br></br>
                He took to coil loaders and took the coil vest,<br></br>
                Why he cleaned out the scanner as quick as a flash,<br></br>
                Why he even stripped the copper for some holiday cash.<br></br><br></br>

                And he huffed up the xenon with an anesthetic glee,<br></br>
                And now!, mumbled Alex, these sequences are all for me!<br></br><br></br>

                And Alex took the console and started to code, when a well-timed cackle, interrupted his download.<br></br>
                He turned around fast, and saw a small who!<br></br>
                Fierce Rachel sault-who, who was not more than five-two!<br></br><br></br>

                Oh Alex had been caught by this mighty who-lady,<br></br>
                Whose legendary side-eye was ever-so shady,<br></br>
                She stared right at Alex, with an exasperated sigh,<br></br>
                Asked, Why are you sabotaging sequences, WHY?<br></br><br></br>

                But, you know, wise old Alex was ever so clever,<br></br>
                He quickly replied, Well I never!<br></br>
                Why my kind younger mentor, sneaky Alex lied,<br></br>
                I just pulled a CT, with emphysema on one side.<br></br>
                So I'm pushing the CT to my desktop my dear,<br></br>
                Then I'll push to the P: drive, never you fear.<br></br><br></br>

                And his fib made some sense, so Rachel departed,<br></br>
                And Alex was finished, what he'd only just started.<br></br>
                And when Rachel sault-who went upstairs to her desk,<br></br>
                Alex's grin grew two sizes, it was quite grotesque.<br></br><br></br>

                There's a sight, grinned Alex,<br></br>
                That I simply must see!<br></br>
                And he deleted all the files, from the drive lettered P:,<br></br>
                And he heard a cry from upstairs in the lab,<br></br>
                But to his dismay it was not at all drab.<br></br><br></br>

                The students worked hard!<br></br>
                Why, they weren't at all scarred,<br></br>
                They all had new papers,<br></br>
                They all looked like stars.<br></br><br></br>

                Every who in the lab, the tall and the small,<br></br>
                Was coding! Without any data at all!<br></br><br></br>

                He hadn't stopped graduation from coming - IT CAME,<br></br>
                Andrew's code made new data that looked just the same!<br></br>
                Maybe science, Alex thought, doesn't come from a drive,<br></br>
                Maybe science, perhaps, is about what's inside.<br></br><br></br>

                And what happened then?<br></br>
                Well in Robarts they say,<br></br>
                That Alex's github grew three sizes that day!<br></br>
                And the minute Alex's plan was no longer airtight,<br></br>
                He was expelled for academic misconduct ... goodnight.<br></br><br></br><br></br>
            </div>
            </div>
            <Footer />
        </div>
    )
};

export default Poetry