import React from "react";
import NavigationBar from "./components/NavigationBar";
import Footer from "./components/Footer";

const OurCode = () => {
    return (
        <div id="everything">
            <NavigationBar />
            <img src="./images/Students in the Deep Learning Room Cropped.png" alt="Students in the Deep Learning Room" style={{width:"100%"}} />
            <div className='text-center text-black justify-center py-4 text-xl md:text-5xl lg: text-6xl xl:text-7xl'>
                Available Code
            </div>
            <div className='text-left text-black justify-left mx-12 text-base md:text-lg lg:text-xl xl:text-2xl'>
                CNN Registration and Segmentation to Calculate VDP
            </div>
            <br></br>
            <div className='text-left text-black justify-left mx-12 text-base md:text-lg lg:text-xl xl:text-2xl'>
                The first download is the Deep Learning Code and the second is the saved network weights. Both must be downloaded to run the registration and 
                segmentation code. Once the code is downloaded, please see the README file for further instructions on setting up and running the code on your PC.
            </div>
            <div className="video-container">
                <a href="/files/DeepLearning_Code_Aug_19_2024_efsremoved.zip">
                    <p className="button" style={{width: "100%"}}>Deep Learning Code</p>
                </a>
            </div>
            <div className="video-container">
                <a href="/files/Saved_Network_Weights.zip">
                    <p className="button" style={{width: "100%"}}>Saved Weights</p>
                </a>
            </div>
            <br></br>
            <div className="video-container">
                <img src="./images/PiPLogo.jpg" style={{ alignSelf: "center", width:"20%" }} alt="Pip Logo"/>
            </div>
            <br></br>
            <div className='text-left text-black justify-left mx-12 text-base md:text-lg lg:text-xl xl:text-2xl'>
                This package provides the main components of the pulmonary MRI and CT biomarker framework including:
            </div>
            <br></br>
            <div className='text-left text-black justify-left mx-12 text-base md:text-lg lg:text-xl xl:text-2xl'>
                I. The software GUI (need Visual C++ Redistributable Packages for Visual Studio 2013 
                to run). The source code and the .exe GUI-based 3D visualizer are not provided because 
                of copyright issues. However, readers who are interested in the visualizer can contact 
                Dr. Aaron Fenster at: afenster@robarts.ca
            </div>
            <br></br>
            <div className="video-container">
                <a href="/files/GUI.zip">
                    <p className="button" style={{width: "100%"}}>Download Here</p>
                </a>
            </div>
            <br></br>
            <div className='text-left text-black justify-left mx-12 text-base md:text-lg lg:text-xl xl:text-2xl'>
                II. Three representative biomarker modules (the remainder will be added soon):
            </div>
            <br></br>
            <div className='text-left text-black justify-left mx-16 text-base md:text-lg lg:text-xl xl:text-2xl'>
                a. Whole lung and lobar noble gas MRI ventillation mesurements
            </div>
            <div className='text-left text-black justify-left mx-16 text-base md:text-lg lg:text-xl xl:text-2xl'>
                Guo, Fumin, et al. "Globally optimal co-segmentation of three-dimensional pulmonary 
                <sup>1</sup>H and hyperpolarized <sup>3</sup>He MRI with spatial consistence prior."
                 Medical image analysis 23.1 (2015): 43-55.
            </div>
            <div className='text-left text-black justify-left mx-16 text-base md:text-lg lg:text-xl xl:text-2xl'>
                Guo, Fumin, et al. "Thoracic CT-MRI coregistration for regional pulmonary structure-function 
                measurements of obstructive lung disease." Medical physics 44.5 (2017): 1718-1733.
            </div>
            <br></br>
            <div className="video-container">
            <a href="/files/3HeVDP.zip">
                <p className="button" style={{width:"100%"}}>Download Here</p>
            </a>
            </div>
            <br></br>
            <div className='text-left text-black justify-left mx-16 text-base md:text-lg lg:text-xl xl:text-2xl'>
                b. Multi-volume UTE MRI dynamic proton density maps
            </div>
            <div className='text-left text-black justify-left mx-16 text-base md:text-lg lg:text-xl xl:text-2xl'>
                Sheikh, Khadija, et al. "Ultrashort echo time MRI biomarkers of asthma." Journal of Magnetic 
                Resonance Imaging 45.4 (2017): 1204-1215.
            </div>
            <br></br>
            <div className="video-container">
            <a href="/files/UTE.zip">
                <p className="button" style={{width: "100%"}}>Download Here</p>
            </a>
            </div>
            <br></br>
            <div className='text-left text-black justify-left mx-16 text-base md:text-lg lg:text-xl xl:text-2xl'>
                c. Inspiration and expiration CT (MRI) parametric response maps
            </div>
            <div className='text-left text-black justify-left mx-16 text-base md:text-lg lg:text-xl xl:text-2xl'>
                Capaldi, Dante PI, et al. "Pulmonary imaging biomarkers of gas trapping and emphysema in COPD: 
                <sup>3</sup>He MR imaging and CT parametric response maps." Radiology 279.2 (2016): 597-608.
            </div>
            <br></br>
            <div className="video-container">
            <a href="/files/PRM.zip">
                <p className="button" style={{width:"100%"}}>download here</p>
            </a>
            </div>
            <br></br>
            <div className='text-left text-black justify-left mx-16 text-base md:text-lg lg:text-xl xl:text-2xl'>
                d. Fourier decomposition of free breathing <sup>1</sup>H MRI ventilation measurements
            </div>
            <br></br>
            <div className="video-container">
            <a href="/files/FDMRI.zip">
                <p className="button" style={{width:"100%"}}>download here</p>
            </a>
            </div>
            <br></br>
            <div className='text-left text-black justify-left mx-16 text-base md:text-lg lg:text-xl xl:text-2xl'>
                e. <sup>1</sup>H MRI specific ventilation measurements (not provided as this work
                is protected)
            </div>
            <div className='text-left text-black justify-left mx-16 text-base md:text-lg lg:text-xl xl:text-2xl'>
                Capaldi, Dante PI, et al. "Free-Breathing Pulmonary MR Imaging to Quantify Regional 
                Ventilation." Radiology 287.2 (2018): 693-704
            </div>
            <br></br>
            <div className='text-left text-black justify-left mx-12 text-base md:text-lg lg:text-xl xl:text-2xl'>
                III. Commonly used Matlab functionalities (these .m files need to be added to the Matlab 
                path)- Please refer to the "RunMe.txt" in each folder for specific components 
            </div>
            <br></br>
            <div className="video-container">
            <a href="apilab.ca">
                <p className="button" style={{width:"100%"}}>download here</p>
            </a>
            </div>
            <br></br>
            <div className='text-left text-black justify-left mx-12 text-base md:text-lg lg:text-xl xl:text-2xl'>
                IV. Machine Learning based algorithms:
            </div>
            <br></br>
            <div className='text-left text-black justify-left mx-16 text-base md:text-lg lg:text-xl xl:text-2xl'>
                a. CT to MRI texture analysis
            </div>
            <br></br>
            <div className="video-container">
            <a href="/files/CT to MRI Texture Analysis.zip">
                <p className="button" style={{width:"100%"}}>download here</p>
            </a>
            </div>
            <br></br>
            <div className='text-left text-black justify-left mx-12 text-base md:text-lg lg:text-xl xl:text-2xl'>
                V. Asthma Structure-Function Relationship
            </div>
            <div className="bg-white h-50px w-full grid md:grid-cols-2 sm:grid-cols-1 justify-around items-start p-4">
                <div className="video-container">
                    <video controls style={{width:"95%"}}>
                        <source src="./files/ROB0042-030 Video.mp4" />
                    </video>
                </div>
                <div className="video-container">
                    <video controls style={{width:"95%"}}>
                        <source src="./files/ROB0042-019 Video.mp4" />
                    </video>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default OurCode 