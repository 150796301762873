import React from "react";
import NavigationBar from "./components/NavigationBar";
import Footer from "./components/Footer";
import PersonInfo from "./PersonInfo";
import StaffInfo from "./StaffInfo";
import UndergradInfo from "./UndergradInfo";

const OurTeam = () => {
  return (
    <div id="everything">
      <NavigationBar />
      <img src="./images/White Shirts on Stairs cropped.png" width={`100%`} alt="Staff and Students in the API Lab" />
      <br></br>
      <div className='text-center text-black justify-center text-xl md:text-5xl lg: text-6xl xl:text-7xl'>
        Lab Members
      </div>
      <br></br>
      <div className='text-left font-bold text-black mx-12 justify-center text-lg md:text-3xl lg: text-4xl xl:text-4xl'>
        Students
      </div>
      <br></br>
      <div className="mx-6">
      <PersonInfo 
        name="Sam Tcherner"
        degree="MSc Trainee"
        project="Deep Learning Segmentation of Pulmonary Airways and Lobes from <sup>1</sup>H and <sup>129</sup>Xe MRI for the Extraction of Quantitative Airway Measurements"
        education="BSc Honours Medical and Biological Physics, McMaster University"
        email="stcherne@uwo.ca"
        StartDate="May 2023"
        imageUrl="./images/SamT.png"
      />
      <br></br>
      <PersonInfo
        name="Ali Mozaffaripour"
        degree="MESc Trainee"
        project="Investigation of Airways Resistance using Hyperpolarized <sup>129</sup>Xe MRI Texture Features and Machine Learning"
        education="BMSc Honours Medical Biophysics, Western University"
        email="amozaff@uwo.ca"
        StartDate="May 2023"
        imageUrl="./images/AliM.png"
      />
      <br></br>
      <PersonInfo
        name="Eveline Durom"
        degree="MESc Trainee"
        project="Artificial Intelligence-based Approach for Detecting Mucus Plugs in Lung CT Images"
        education="BMSc Honours Medical Biophysics, Western University"
        email="edurom@uwo.ca"
        StartDate="September 2024"
        imageUrl="./images/EvelineD.png"
      />
      {/* <br></br>
      <UndergradInfo
        name="Omar Rahman"
        title="Co-op Student"
        email="orahman7@uwo.ca"
        StartDate="January 2024"
        imageUrl="./images/OmarR.png"
      /> */}
      {/* <br></br> */}
      {/* <UndergradInfo
        name="Kathryn Taberner"
        title="Medical Resident"
        email="ktaberne@uwo.ca"
        StartDate="April 2024"
        imageUrl="./images/KathrynT.png"
      /> */}
      {/* <br></br>
      <UndergradInfo
        name="Yvette Young"
        title="Undergraduate Trainee"
        email="yyoung4@uwo.ca"
        StartDate="May 2024"
        imageUrl="./images/YvetteY.png"
      /> */}
      <br></br><UndergradInfo
        name="Olivia Gater"
        title="Undergraduate Trainee"
        email="ogater2@uwo.ca"
        StartDate="May 2024"
        imageUrl="./images/OliviaG.png"
      />
      <br></br>
      <UndergradInfo
        name="Josh Peters"
        title="Undergraduate Trainee"
        email="jpete28@uwo.ca"
        StartDate="May 2024"
        imageUrl="./images/JoshP.png"
      />
      <br></br><UndergradInfo
        name="Eshan Patel"
        title="Undergraduate Trainee"
        email="epatel26@uwo.ca"
        StartDate="September 2024"
        imageUrl="./images/EshanP.png"
      />
      <br></br><UndergradInfo
        name="Saylor Sprenger"
        title="Undergraduate Trainee"
        email="sspreng5@uwo.ca"
        StartDate="September 2024"
        imageUrl="./images/SaylorS.png"
      />
      {/* <UndergradInfo
        name="Sourya Mukherjee"
        title="Co-op Student - Data Analytics and Artificial Intelligence"
        email="smukhe27@uwo.ca"
        StartDate="May 2024"
        imageUrl="./images/SouryaM.png"
      /> */}
      <br></br>
      <br></br>
      </div>
      <div className='text-left font-bold text-black mx-12 justify-center text-lg md:text-3xl lg: text-4xl xl:text-4xl'>
        Staff
      </div>
      <br></br>
      <div className="mx-6">
      <StaffInfo 
        name="Angela Wilson"
        title="Clinical Research Manager"
        education="RRT, Fanshawe College"
        email="awilson@robarts.ca"
        StartDate="June 2020"
        imageUrl="./images/AngelaW.png"
      />
      {/* <br></br> */}
      {/* <StaffInfo 
        name="Lauren Zelko"
        title="Regulatory and Data Coordinator"
        education="MSc, Medical Biophysics, Western University"
        email="lzelko@uwo.ca"
        StartDate="July 2023"
        imageUrl="./images/LaurenZ.png"
      /> */}
      </div>
      <br></br>
      <br></br>
      <div className="person-info-container">
        <div className="headshot-container">
          <div className="mx-6">
          <img src="./images/GraceP.png" className="headshot" alt="Grace Parraga Head Shot" />
          </div>
        </div>
        <div className="info">
          <div className='text-left text-black justify-left mx-12 text-lg md:text-xl lg:text-2xl xl:text-4xl'>
            Dr. Grace Parraga
          </div>
          <div className='text-left text-black justify-left mx-12 text-base md:text-lg lg:text-xl xl:text-4xl'>
            <strong>Email:</strong> gparraga@uwo.ca
          </div>
        </div>
      </div>
      <div className="flex flex-col items-left"> 
      <br></br>
        <div className='text-left text-black justify-left mx-12 text-base md:text-lg lg:text-xl xl:text-4xl'>
          Dr. Parraga completed her BSc (Hon) and MSc in Biochemistry at The University of Western Ontario. 
          She trained with Dr. Rachel E Klevit in Seattle and completed her PhD in Dr. Klevit's lab in the 
          Department of Biochemistry at the University of Washington. Her thesis work was published in Science, 
          PNAS and Methods in Enzymology. After completing post-doctoral studies at the Biozentrum, University 
          of Basel, Switzerland, she joined F. Hoffmann La Roche AG as a Scientist in Pharma Research and 
          Development. In 2004, she returned to academic research at Robarts Research Institute, The University
          of Western Ontario.
        </div>
        <br></br>
        <div className='text-left text-black justify-left mx-12 text-base md:text-lg lg:text-xl xl:text-4xl'>
          Her current research has been published in Circulation, Radiology, Thorax, Medical Physics, New 
          England Journal of Medicine and the American Journal of Respiratory and Critical Care Medicine. She 
          currently holds a CIHR New Investigator Award and leads the Imaging platform of the Canadian Respiratory 
          Research Network, recently funded by CIHR. Dr. Parraga also Co-Chairs with Dr Teresa To, the Canadian 
          Lung Association RENASCENT Training and Mentorship Committee that is generating a training framework for
          respiratory research training across Canada.
        </div>
        <br></br>
        <div className='text-left text-black justify-left mx-12 text-base md:text-lg lg:text-xl xl:text-4xl'>
          Dr. Parraga is Professor (with tenure) in the Department of Medical Biophysics, Western University and 
          is also appointed in the Departments of Medicine, Oncology and the School of Biomedical Engineering at 
          Western. She is adjunct Professor in Physics at Dalhousie University. Ongoing pulmonary research funding 
          is provided by the Natural Sciences and Engineering Research Council of Canada, CIHR Operating, Network 
          and Team Grants.
        </div>
        <br></br>
      </div>
      <Footer />
    </div>
  );
};

export default OurTeam;